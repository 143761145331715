import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { exportToPDF } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch } from "react-redux";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SearchDialogManager from "../../components/DialogBox/SearchDialogManager";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";
import {
  changeSingleStatus,
} from "../../Redux/Actions/ProvideRegistration";
import { getMerchantListDataExport } from "../../Redux/Actions/genrateOfferPatient";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../assets/svgIcons/AddIconSvg.svg";
import {
  sendInviteApiCall,
  sendInviteManagerApiCall,
} from "../../actions/Operations";
import {
  getMerchantListData,
  getManagerListData,
  emptyReduxState,
  getManagerListDataByID,
  setFormDataManager,
  clearFormDataManager,
  getManagerListDataNames,
  getEmailData1,
} from "../../Redux/Actions/genrateOfferPatient";
import InviteManagerModal from "../../components/Modal/InvitemanagerModal";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SyncIcon from "@material-ui/icons/Sync";
import { useSnackbar } from "notistack";

const IconsTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);
function createData(
  Business_Name,
  Manager_name,
  Manager_uid,
  Email,
  Business_Phone,
  Updated_date,
  Status_Code,
  Bussines_id
) {
  return {
    Business_Name,
    Manager_name,
    Manager_uid,
    Email,
    Business_Phone,
    Updated_date,
    Status_Code,
    Bussines_id,
  };
}

// let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Business_Name",
      numeric: false,
      disablePadding: true,
      label: "Business Name",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Manager_name",
      numeric: false,
      disablePadding: false,
      label: "Manager Name",
      headClassName: classes.Assign_To,
    },
    {
      id: "Manager_uid",
      numeric: false,
      disablePadding: false,
      label: "Manager UID",
      headClassName: classes.Assign_To,
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email Address",
      headClassName: classes.email,
    },
    {
      id: "Phone_Number",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
      headClassName: classes.Phone,
    },
    {
      id: "Updated_date",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
      headClassName: classes.Phone,
    },
    {
      id: "Status_Code",
      numeric: false,
      disablePadding: false,
      label: "Manager Status",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ProviderReport = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Updated_date");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const theme = useTheme();
  
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchBar, setSearchBar] = React.useState(false);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const dispatch = useDispatch();
  const [inviteManagerModalOpen, setInviteManagerModalOpen] = React.useState(false);
  const [transformedData, setTransformedData] = React.useState([]);
  const [rows, setRows] = React.useState([])
  const completeData = props?.managerListData?.managerListNames;
  const [totalCount, setTotalCount] = React.useState(0);
  console.log("rows",rows)
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;
  const { enqueueSnackbar } = useSnackbar();

  const handleAddIconClick = () => {
    if (id == null) {
      localStorage.removeItem("businessName");
    }
    setInviteManagerModalOpen(true);
  };

  const handleToggle = () => {
    setImportFileModalOpen(prevOpen => !prevOpen);
  };

  const handleCloseModel = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  React.useEffect(() => {
    if (menu?.Email) {
      dispatch(getEmailData1(menu?.Email));
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      const payloadWhenId = {
        Bussines_id: id,
        recordPerPage: 999999,
      };
      dispatch(getManagerListDataNames(payloadWhenId));
    } else if (id == null) {
      const payload = {
        Profile_Id: profileId,
        recordPerPage: 999999,
      };
      dispatch(getManagerListDataNames(payload));
    }
  }, [id]);

  React.useEffect(() => () => {
    const updatedFormData = {
      searchBusinessName: "",
      searchEmail: "",
      searchStatus: "",
      searchManagerUID: "",
      searchManagerName: "",
      searchManagerId: "",
    };
    props.setFormDataManager(updatedFormData);
    props.clearFormDataManager();
  }, []);

  React.useEffect(() => {
    const result = completeData?.data?.map(items => ({
      Business_Name: items?.Business_Details?.Business_Name,
      Email: items?.Email,
      Manager_uid: items.Manager_uid,
      _id: items?._id,
      First_Name: items?.First_Name,
      Last_Name: items?.Last_Name,
      Phone_Number: items?.Phone_Number,
      Updated_date: items?.ManagerStatus?.Updated_date,
      Status_Code: items?.ManagerStatus?.Status,
      User_Id: items.Manager_Details?.User_Id,
      Bussines_id: items?.Business_Details?._id,
      Manager_name: items?.full_name,
    }));
  
    const resultWhenId = completeData?.data?.map(
      items => ({
        Business_Name: items?.Business_Details?.Business_Name,
        Email: items?.Email,
        Manager_uid: items.Manager_uid,
        _id: items?._id,
        First_Name: items?.First_Name,
        Last_Name: items?.Last_Name,
        Phone_Number: items?.Phone_Number,
        Status_Code: items?.ManagerStatus?.Status,
        User_Id: items.Manager_Details?.User_Id,
        Bussines_id: items?.Business_Details?._id,
        Updated_date: items?.ManagerStatus?.Updated_date,
        Manager_name: items?.full_name,
      })
    );
  
    const newResult =
      id == null &&
      props.managerListData &&
      props.managerListData?.managerListNames?.status === true
        ? result
        : resultWhenId;
  
    setTransformedData(newResult || []);
  }, [completeData, id]);

  React.useEffect(() => {
    const applyFilters = (data) => {
      return data.filter(row => {
        const matchesBusinessName = props.managerListData?.searchBusinessName ? 
          row.Business_Name?.toLowerCase().includes(props.managerListData.searchBusinessName.toLowerCase()) : true;
        const matchesEmail = props.managerListData?.searchEmail ? 
          row.Email?.toLowerCase().includes(props.managerListData.searchEmail.toLowerCase()) : true;
        const matchesManagerUID = props.managerListData?.searchManagerUID ? 
          row.Manager_uid?.toLowerCase().includes(props.managerListData.searchManagerUID.toLowerCase()) : true;
        const matchesStatus = props.managerListData?.searchStatus ? 
          row.Status_Code?.toLowerCase() === props.managerListData.searchStatus.toLowerCase() : true;
        const matchesManagerId = props.managerListData?.searchManagerId ? 
          row._id?.toLowerCase().includes(props.managerListData.searchManagerId.toLowerCase()) : true;
        return (
          matchesBusinessName &&
          matchesEmail &&
          matchesManagerUID &&
          matchesStatus &&
          matchesManagerId
        );
      });
    };

    const sortData = (data) => {
      return data.sort((a, b) => {
        const aValue = getPropertyNested(a, orderBy);
        const bValue = getPropertyNested(b, orderBy);

        if (order === "asc") {
          if (aValue === null || aValue === undefined) return -1;
          if (bValue === null || bValue === undefined) return 1;
          return aValue > bValue ? 1 : -1;
        } else {
          if (aValue === null || aValue === undefined) return 1;
          if (bValue === null || bValue === undefined) return -1;
          return aValue < bValue ? 1 : -1;
        }
      });
    };

    const filterAndSortData = (data) => {
      const filteredData = applyFilters(data);
      const sortedData = sortData(filteredData);
      return sortedData;
    };
    const updateRows = (data) => {
      debugger
      const filteredAndSortedData = filterAndSortData(transformedData);
      setTotalCount(filteredAndSortedData.length);
      const paginatedData = filteredAndSortedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      const newRows = paginatedData.map((v) => {
        const Business_Name = v?.Business_Name;
        const Email = v.Email;
        const Business_Phone = v.Phone_Number;
        const Updated_date = moment(v.Updated_date).format("MM/DD/YYYY");
        const Manager_name = v.Manager_name;
        const Manager_uid = v.Manager_uid;
        const Status_Code = v.Status_Code;
        const Bussines_id = v.Bussines_id;

        return createData(
          Business_Name,
          Manager_name,
          Manager_uid,
          Email,
          Business_Phone,
          Updated_date,
          Status_Code,
          Bussines_id
        );
      });

      setRows(newRows);
    };

    updateRows();
  }, [page, rowsPerPage, order, orderBy, transformedData, props.managerListData]);

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const allManagerData = props?.managerListData?.managerListNames || [];
    let filteredList = allManagerData.data;
  if (
      props?.managerListData?.searchBusinessName ||
      props?.managerListData?.searchEmail ||
      props?.managerListData?.searchManagerName ||
      props?.managerListData?.searchManagerUID ||
      props?.managerListData?.searchStatus
  ) {
      filteredList = allManagerData.data.filter(row => {
          const matchesBusinessName = props?.managerListData?.searchBusinessName ? 
              row.Business_Details?.Business_Name.toLowerCase().includes(props?.managerListData?.searchBusinessName.toLowerCase()) : true;
          const matchesEmail = props?.managerListData?.searchEmail ? 
              row.Email.toLowerCase().includes(props?.managerListData?.searchEmail.toLowerCase()) : true;
          const matchesManagerUID = props?.managerListData?.searchManagerUID ? 
              row.Manager_uid.toLowerCase().includes(props?.managerListData?.searchManagerUID.toLowerCase()) : true;
          const matchesStatus = props?.managerListData?.searchStatus ? 
              row.ManagerStatus?.Status.toLowerCase() === props?.managerListData?.searchStatus.toLowerCase() : true;
          const matchesManagerId = props?.managerListData?.searchManagerId ? 
              row._id.toLowerCase().includes(props?.managerListData?.searchManagerId.toLowerCase()) : true;
          return (
              matchesBusinessName &&
              matchesEmail &&
              matchesManagerUID &&
              matchesStatus &&
              matchesManagerId
          );
      });
  }
  
    const mappedLogs = filteredList.map(row => ({
      "Business Name": row.Business_Details?.Business_Name,
      "Manager Name": row.full_name,
      "Manager UID": row.Manager_uid,
      Email: row.Email,
      "Phone Number": row.Phone_Number,
      "Update Date": moment(row.Updated_date).format("MM/DD/YYYY"),
      "Status": row.Provider_Status,
    }));
    exportToCSVProvider(
      mappedLogs,
      "Manager Details",
      chipValue,
      exportedBy,
      exportedByFname,
      exportedByLname
    );
  };

  const exportToPDFList = () => {
    const allManagerData = props?.managerListData?.managerListNames || [];
    const applyFilters = (data) => {
      return data.filter(row => {
        const matchesBusinessName = props?.managerListData?.searchBusinessName ? 
          row.Business_Details?.Business_Name.toLowerCase().includes(props?.managerListData?.searchBusinessName.toLowerCase()) : true;
        const matchesEmail = props?.managerListData?.searchEmail ? 
          row.Email.toLowerCase().includes(props?.managerListData?.searchEmail.toLowerCase()) : true;
        const matchesManagerUID = props?.managerListData?.searchManagerUID ? 
          row.Manager_uid.toLowerCase().includes(props?.managerListData?.searchManagerUID.toLowerCase()) : true;
        const matchesStatus = props?.managerListData?.searchStatus ? 
          row.ManagerStatus?.Status.toLowerCase() === props?.managerListData?.searchStatus.toLowerCase() : true;
        const matchesManagerId = props?.managerListData?.searchManagerId ? 
          row._id.toLowerCase().includes(props?.managerListData?.searchManagerId.toLowerCase()) : true;
        return (
          matchesBusinessName &&
          matchesEmail &&
          matchesManagerUID &&
          matchesStatus &&
          matchesManagerId
        );
      });
    };
  
    let dataToExport = allManagerData.data;
    
    dataToExport = applyFilters(dataToExport);
  
    const totalCount = dataToExport.length;
    if (totalCount > 0) {
      const mappedLogs = dataToExport.map(row => [
        row.Business_Details?.Business_Name,
        row.full_name,
        row.Manager_uid,
        row.Email,
        row.Phone_Number,
        moment(row.Updated_date).format("MM/DD/YYYY"),
        row.Status_Code,
      ]);
  
      const headersName = [
        "Business Name",
        "Manager Name",
        "Manager UID",
        "Email",
        "Phone Number",
        "Update Date",
        "Status",
      ];
  
      exportToPDF(
        headersName,
        mappedLogs,
        "Manager List",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname,
        totalCount
      );
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };
  
  // Helper function to safely access nested properties
  const getPropertyNested = (object, property) => {
    const properties = property.split(".");
    let value = object;

    for (let prop of properties) {
      value = value[prop];
      if (value === undefined || value === null) return undefined;
      if (Array.isArray(value)) {
        value = value.length > 0 ? value[0] : undefined;
      }
    }

    return value;
  };

  const displayAndDeleteChips = searchData => {
    const chips = [];
    if (searchData.searchBusinessName) {
      chips.push(`Business Name: ${searchData.searchBusinessName}`);
    }
    if (searchData.searchManagerUID) {
      chips.push(`Manager UID: ${searchData.searchManagerUID}`);
    }
    if (searchData.searchEmail) {
      chips.push(`Email: ${searchData.searchEmail}`);
    }
    if (searchData.searchStatus) {
      chips.push(`Status: ${searchData.searchStatus}`);
    }
    if (searchData.searchManagerName) {
      chips.push(`Manager Name: ${searchData.searchManagerName}`);
    }
    setChipValue(chips);
  };

  const handleDeleteChip = chipToDelete => {
    const updatedChips = chipValue.filter(chip => chip !== chipToDelete);
    setChipValue(updatedChips);

    const updatedFormData = {
      searchBusinessName: "",
      searchEmail: "",
      searchStatus: "",
      searchManagerUID: "",
      searchManagerName: "",
      searchManagerId: "",
    };

    updatedChips.forEach(chip => {
      const [key, value] = chip.split(":").map(item => item.trim());
      switch (key) {
        case "Email":
          updatedFormData["searchEmail"] = value;
          break;
        case "Business Name":
          updatedFormData["searchBusinessName"] = value;
          break;
        case "Status":
          updatedFormData["searchStatus"] = value;
          break;
        case "Manager Name":
          updatedFormData["searchManagerName"] = value;
          updatedFormData["searchManagerId"] = value !== null || value !== "" ? props.managerListData?.searchManagerId : "";
          break;
        case "Manager UID":
          updatedFormData["searchManagerUID"] = value;
          break;
        default:
          break;
      }
    });

    props.clearFormDataManager();
    props.setFormDataManager(updatedFormData);
    setPage(0);
  };

  const handleBack = () =>{
    localStorage.setItem("BackPressed",true);
    props.history.push(`/${props.mainData.app.layout}/MerchantSetup`);
  }

  const handleRefresh = () =>{
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_Id: profileId,
        recordPerPage: 999999,
      };
      dispatch(getManagerListDataNames(payload));
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 999999,
      };
      dispatch(getManagerListDataNames(payload));
    }
  }

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(`${text} copied to clipboard`, { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar("Failed to copy text to clipboard", {
          variant: "error",
        });
      });
  };

  const userType = localStorage.getItem("userType");
  const createPermission = actionPermission(
    permissionCheck.Category.setup,
    permissionCheck.Page.managerList,
    permissionCheck.option.add
  );

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper1}>
                {id !== null && 
                  <IconsTooltip title="Back" placement="top">
                    <div style={{display:"flex", alignSelf:"flex-end"}}>
                      <ArrowBackIcon color="primary" onClick = {handleBack}/>
                    </div>
                  </IconsTooltip>
                }
                <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SearchDialogManager
                      close={() => handleSearchClose()}
                      seacrhChipData={data => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Refresh" placement="top">
                  <div className={classes.appSvgIcons}>
                    <SyncIcon style={{marginRight:"10px"}} fontSize = "large" color= "primary" onClick={handleRefresh}/>
                  </div>
                </IconsTooltip>
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.managerList,
                  permissionCheck.option.add
                ) &&
                profileCode !== "Ag" &&
                props?.managerListData?.emailData1?.Mg_Status === "Active" &&(
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={handleAddIconClick} />
                    </div>
                  </IconsTooltip>
                )}
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.managerList,
                  permissionCheck.option.add
                ) && 
                profileCode === "Ag" &&(
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={handleAddIconClick} />
                    </div>
                  </IconsTooltip>
                )}
                {inviteManagerModalOpen && (
                  <InviteManagerModal
                    open={inviteManagerModalOpen}
                    onClose={() => setInviteManagerModalOpen(false)}
                    props={props}
                    rows={rows}
                    sendInviteManagerApiCall={props.sendInviteManagerApiCall}
                    Bussines_id={id}
                  />
                )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SearchDialogManager
                      close={() => handleSearchClose()}
                      seacrhChipData={data => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {userType === "Internal" && createPermission && (
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={handleAddIconClick} />
                    </div>
                  </IconsTooltip>
                )}
                {inviteManagerModalOpen && (
                  <InviteManagerModal
                    open={inviteManagerModalOpen}
                    onClose={() => setInviteManagerModalOpen(false)}
                    props={props}
                    rows={rows}
                    sendInviteManagerApiCall={props.sendInviteManagerApiCall}
                    Bussines_id={id}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        )}
        <SearchChips
          searchValues={chipValue}
          handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map(row => (
                <TableRow className={classes.cellHeadSign}>
                  <TableCell className={classes.cellText}>
                    {row.Business_Name}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Manager_name}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => copyToClipboard(row.Manager_uid)}
                  >
                    <IconsTooltip title={row.Manager_uid?"Click to copy":""} placement="top">
                    <span>{row.Manager_uid}</span>
                    </IconsTooltip>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Email}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Business_Phone}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Updated_date}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Status_Code}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows?.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: totalCount
                    },
                  ]}
                  colSpan={12}
                  count={totalCount}
                  rowsPerPage={rowsPerPage || 5}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderReport.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getMerchantListDataExport: PropTypes.func,
  getMerchantListData: PropTypes.func,
  getManagerListData: PropTypes.func,
  getManagerListDataByID: PropTypes.func,
  sendInviteApiCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  sendInviteManagerApiCall: PropTypes.func,
  getManagerListDataNames: PropTypes.func,
  getEmailData1: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  managerListData: app.genrateOfferPatient,
  managerListDataById: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  changeSingleStatus,
  getMerchantListDataExport,
  getMerchantListData,
  getManagerListData,
  getManagerListDataByID,
  sendInviteApiCall,
  emptyReduxState,
  sendInviteManagerApiCall,
  setFormDataManager,
  clearFormDataManager,
  getEmailData1,
})(ProviderReport);
