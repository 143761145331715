import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";

export const exportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname
) => {
  const myCurrentDate = new Date();
  const date = `${myCurrentDate.getMonth()
    + 1
  }/${
    myCurrentDate.getDate()
  }/${
    myCurrentDate.getFullYear()
  } ${
    myCurrentDate.getHours()
  }:${
    myCurrentDate.getMinutes()
  }:${
    myCurrentDate.getSeconds()}`;
  const newCurrentDate = date;
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [["Module:"]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [["Exported on:"]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`,
      ],
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [["Exported by:"]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`,
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach(row => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map(width => ({ wch: width }));

  XLSX.utils.sheet_add_aoa(ws, [["Filters:"]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${chipValue}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `${fileName
    }${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}${
      fileExtension}`
  );
};

export const exportToCSVProvider = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname
) => {
  const workbook = { SheetNames: [], Sheets: {} };

  // Create an empty worksheet and add metadata at the top
  const worksheet = XLSX.utils.aoa_to_sheet([
    ["Module:", fileName],
    ["Exported on:", `${moment().tz("UTC").format("MM/DD/YYYY hh:mm:ss A")} UTC`],
    ["Exported by:", `${exportedByFname} ${exportedByLname} <${exportedBy}>`],
    ["Filters:", chipValue],
    [], // Empty row to separate metadata from data
  ]);

  // Add headers to the worksheet before adding data
  const headers = Object.keys(mappedLogs[0] || {});
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A6" });
  // Add the actual data starting from row 7
  XLSX.utils.sheet_add_json(worksheet, mappedLogs, { origin: "A7", skipHeader: true });
  const colWidths = headers.map(header => {
    const columnData = mappedLogs.map(row => row[header]?.toString() || "");
    const headerLength = header.length;
    const maxLength = Math.max(...columnData.map(data => data.length), headerLength);
    return { wch: maxLength + 2 }; // Add some padding to the width
  });
  worksheet['!cols'] = colWidths;

  // Apply filters to the header row (row 6)
  const headerRange = `A6:${XLSX.utils.encode_col(headers.length - 1)}6`;
  worksheet['!autofilter'] = { ref: headerRange };

  // Add the sheet to the workbook
  workbook.SheetNames.push("data");
  workbook.Sheets["data"] = worksheet;

  // Write the workbook with compression
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
    compression: true, // Enable compression to reduce file size
  });
  const saveAsExcelFile = (buffer, fileName) => {
    const myCurrentDate = new Date();
    const date = `${myCurrentDate.getMonth()
      + 1
    }/${
      myCurrentDate.getDate()
    }/${
      myCurrentDate.getFullYear()
    } ${
      myCurrentDate.getHours()
    }:${
      myCurrentDate.getMinutes()
    }:${
      myCurrentDate.getSeconds()}`;
    const newCurrentDate = date;
    const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, `${fileName}_${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}.xlsx`);
  };
  saveAsExcelFile(excelBuffer, fileName);
};

export const exportToXLS = (mappedLogs, fileName) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A1" }
  );
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A1:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}1`,
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }

  const myCurrentDate = new Date();
  const date = `${myCurrentDate.getMonth()
    + 1
  }/${
    myCurrentDate.getDate()
  }/${
    myCurrentDate.getFullYear()
  } ${
    myCurrentDate.getHours()
  }:${
    myCurrentDate.getMinutes()
  }:${
    myCurrentDate.getSeconds()}`;
  const newCurrentDate = date;
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach(row => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map(width => ({ wch: width }));

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `${fileName
    }${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}${
      fileExtension}`
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const permissionCheck = {
  Category: {
    reports: 1,
    setup: 2,
    residual: 3,
  },
  Page: {
    merchant: 1,
    user: 2,
    roles: 3,
    groups: 4,
    merchantInvite: 6,
    pricingTemplate: 7,
    PayoutSummary: 11,
    PayoutDetail: 12,
    IncomeExpenseDetail: 13,
    AdjustmentDetails: 14,
    CalculationSummary: 15,
    ImportLogs: 16,
    CalculationHistory: 17,
    PricingSchedule: 1,
    PayoutApproval: 6,
    AgentPayout: 2,
    MerchantMapping: 3,
    PayoutCalculation: 4,
    Adjustment: 5,
    managerList:34
  },
  option: {
    view: 1,
    add: 2,
    edit: 3,
    export: 4,
    exportToPdf: 5,
    exportToExcel: 6,
    clone: 7,
    import: 8,
    changeStatus: 9,
    setPermissions: 10,
    associatedUsers: 11,
    resetPassword: 12,
    viewHierarchy: 13,
    allowToBoard: 14, // merchant boarding
    allowToChecklist: 15, // merchant boarding
    allowToLive: 16, // merchant boarding
    viewPricing: 17, // merchant boarding
    activityHistory: 18,
    additionalInformation: 19, // merchant boarding
    sendMerchantSignupLink: 20,
    history: 21, // Calculation history
    addAdjustment: 22,
    addStatusPermission: 23,
    updateDBA: 24,
    allowToChangeStatus: 25,
    submit: 26,
    saveDraft: 27,
    sendInvite: 28,
    inviteManager: 29,
    submit: 30,
    resendInvite: 31,
    asterisk:32,
  },
};

export const actionPermission = (catId, pageId, type) => {

  
  const roleData = JSON.parse(localStorage.getItem("rolePermission"));
  
  // let roles = roleData.roleTab.Roles_Data
  // if(roleData.roleTab){
  //   roles = roleData.roleTab.Roles_Data
  // }
  const roles = get(roleData, "roleTab.Roles_Data", false);
  // if (isEmpty(roles) || !catId || !pageId || !type) {
  //   return roles;
  // }
  if (!isEmpty(roles.Master_Permission)) {
    const mainCat = roles.Master_Permission.filter(
      cat => cat.Cat_Code === catId
    );
    let permission = !!mainCat;
    if (!permission) {
      return false;
    }

    const pageDetails = mainCat[0]?.Page_Menu.filter(
      page => page.Page_Code === pageId
    );
    permission = !!pageDetails;
    if (!permission) {
      return false;
    }
    const permissionData = pageDetails[0]?.Options.find(
      per => per.value === type
    );
    if (permissionData) {
      return permissionData.permission;
    }
    return false;
  }
};

export function formatPhoneNumber(v) {
  let r = v.replace(/\D/g, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    return r;
  } if (r.length > 6) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
  } else if (r.length > 3) {
    r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;
  // // let val;
  // val = val.replace(/\D/g, "");
  // val = val.replace(/^(\d{3})/, "$1-");
  // val = val.replace(/-(\d{2})/, "-$1-");
  // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
  // return val;
}

export const getQueryParams = data => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};
