import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { format, subDays } from "date-fns";
import noDataImage from "../../assets/img/noDataImage.png";
import SearchRefund from "../../components/DialogBox/SearchRefund";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import {
  emptyReduxState,
  setFormDataTransactionHistory,
  clearFormDataTransactionHistory,
  getTransactionHistoryData,
  getRefundHistoryDataExport,
} from "../../Redux/Actions/genrateOfferPatient";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";
import { exportToPDF } from "modules/reports/utils/common";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(tableStyle);
const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

function createData(
  Ref_Txn_ID,
  Ref_Txn_Date,
  Ref_Txn_Amount,
  Type,
  Txn_ID,
  Amount,
  Date,
  Status,
  Description
) {
  return {
    Ref_Txn_ID,
    Ref_Txn_Date,
    Ref_Txn_Amount,
    Type,
    Txn_ID,
    Amount,
    Date,
    Status,
    Description,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "transactionId",
      numeric: false,
      disablePadding: true,
      label: "Ref Txn ID",
      headClassName: classes.DBA_Name,
    },
    {
      id: "reftimestamp",
      numeric: false,
      disablePadding: true,
      label: "Ref Txn Date",
      headClassName: classes.E_mail,
    },
    {
      id: "refamount",
      numeric: false,
      disablePadding: false,
      label: "Ref Txn Amount",
      headClassName: classes.Assign_To,
    },
    {
      id: "apiTransactionId",
      numeric: false,
      disablePadding: false,
      label: "Txn ID",
      headClassName: classes.Assign_To,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
      headClassName: classes.Assign_To,
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      headClassName: classes.Creation_Date,
    },
    {
      id: "timestamp",
      numeric: true,
      disablePadding: false,
      label: "Date",
      headClassName: classes.Update_Date,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status,
    },
    {
      id: "error",
      numeric: false,
      disablePadding: false,
      label: "Description",
      headClassName: classes.Update_Date,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const RefundHistory = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(props.transactionHistoryData?.page);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("timestamp");
  const [chipValue, setChipValue] = React.useState([]);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [id, setId] = React.useState(null);
  const [id1, setId1] = React.useState(null);
  const [searchBar, setSearchBar] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);

  React.useEffect(() => {
    setPage(props?.transactionHistoryData?.page);
  }, [props.transactionHistoryData]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const searchTxnID = queryParams.get("searchTxnID");

    if (searchTxnID) {
      setId1(searchTxnID);
    }
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    const setDefaultFilters = () => {
      const today = new Date();
      const sevenDaysAgo = subDays(today, 7);

      const startDate = format(sevenDaysAgo, "yyyy-MM-dd");
      const endDate = format(today, "yyyy-MM-dd");

      const defaultFormData = {
        searchTxnID: "",
        searchStartAmount: "",
        searchEndAmount: "",
        searchStartDate: startDate,
        searchEndDate: endDate,
        dateRange: true,
        amountRange: false,
      };

      props.setFormDataTransactionHistory(defaultFormData);
    };

    setDefaultFilters();
  }, []);

  React.useEffect(() => {
    let searchTxnID = id1;
    let updatedFormData = {
      searchTxnID: id1 || "",
    };
    props.setFormDataTransactionHistory(updatedFormData);
    const queryParams = new URLSearchParams(window.location.search);
    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);
    const startDate = format(sevenDaysAgo, "yyyy-MM-dd");
    const endDate = format(today, "yyyy-MM-dd");

    const chips = [];
    if (searchTxnID) {
      chips.push(`Txn ID: ${searchTxnID}`);
    } else {
      chips.push(`Date From: ${startDate} - To ${endDate}`);
    }
    const payload = {
      pageNo: page,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchTxnID,
      searchStartDate: searchTxnID ? "" : startDate,
      searchEndDate: searchTxnID ? "" : endDate,
      dateRange: !searchTxnID,
      ...(id ? { Bussines_id: id } : { Profile_id: profileId }),
    };

    if (searchTxnID || (!searchTxnID && !queryParams.get("searchTxnID"))) {
      props.getTransactionHistoryData(payload);
      setChipValue(chips);
    }
  }, [id1]);

  React.useEffect(() => {
    let updatedFormData = {
      searchTxnID: "",
      searchStartDate: "",
      searchEndDate: "",
      searchStartAmount: "",
      searchEndAmount: "",
      dateRange: false,
      amountRange: false,
    };
    if (localStorage.getItem("isAmountRangeChecked")) {
      localStorage.removeItem("isAmountRangeChecked");
      updatedFormData["amountRange"] = false;
    }
    if (localStorage.getItem("isDateRangeChecked")) {
      localStorage.removeItem("isDateRangeChecked");
      updatedFormData["dateRange"] = false;
    }
    props.setFormDataTransactionHistory(updatedFormData);
    props.clearFormDataTransactionHistory();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (id) {
      const payload = {
        Bussines_id: id,
        pageNo: newPage + 1,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    } else {
      const payload = {
        Profile_id: profileId,
        pageNo: newPage + 1,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_id: profileId,
        pageNo: 1,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        pageNo: 1,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    }
  };
  const displayAndDeleteChips = (searchData) => {
    const chips = [];
    if (searchData.searchTxnID) {
      chips.push(`Txn ID: ${searchData.searchTxnID}`);
    }
    if (searchData.searchStartAmount && !searchData.searchEndAmount) {
      if (searchData.amountRange === true) {
        chips.push(`Amount From: ${searchData.searchStartAmount}$ - To End`);
      } else {
        chips.push(`Amount: ${searchData.searchStartAmount}$`);
      }
    }
    if (!searchData.searchStartAmount && searchData.searchEndAmount) {
      if (searchData.amountRange === true) {
        chips.push(`Amount From: Start - To ${searchData.searchEndAmount}$`);
      } else {
        chips.push(`Amount: ${searchData.searchStartAmount}$`);
      }
    }
    if (searchData.searchStartAmount && searchData.searchEndAmount) {
      chips.push(
        `Amount From: ${searchData.searchStartAmount}$ - To ${searchData.searchEndAmount}$`
      );
    }
    if (searchData.searchStartDate && !searchData.searchEndDate) {
      const formattedStartDate = format(
        new Date(searchData.searchStartDate),
        "MM/dd/yy"
      );
      if (searchData.dateRange === true) {
        chips.push(`Date From: ${formattedStartDate} - To End`);
      } else {
        chips.push(`Date: ${formattedStartDate}`);
      }
    }
    if (!searchData.searchStartDate && searchData.searchEndDate) {
      const formattedEndDate = format(
        new Date(searchData.searchEndDate),
        "MM/dd/yy"
      );
      if (searchData.dateRange === true) {
        chips.push(`Date From: Start - To ${formattedEndDate}`);
      } else {
        chips.push(`Date: ${formattedEndDate}`);
      }
    }
    if (searchData.searchStartDate && searchData.searchEndDate) {
      const formattedStartDate = format(
        new Date(searchData.searchStartDate),
        "MM/dd/yy"
      );
      const formattedEndDate = format(
        new Date(searchData.searchEndDate),
        "MM/dd/yy"
      );
      chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
    }
    setChipValue(chips);
  };

  const parseAndFormatDate = (dateString) => {
    const parts = dateString.split("/");
    let year = parts[2];
    if (year.length === 2) {
      const currentYear = new Date().getFullYear().toString();
      const currentCentury = currentYear.slice(0, 2);
      year = `${currentCentury}${year}`;
    }
    const month = parts[1].padStart(2, "0");
    const day = parts[0].padStart(2, "0");
    return `${year}-${day}-${month}`;
  };
  const handleDeleteChip = (chipToDelete) => {
    const updatedChips = chipValue.filter((chip) => chip !== chipToDelete);
    setChipValue(updatedChips);
    let payload;
    let updatedFormData = {
      searchTxnID: "",
      searchStartAmount: "",
      searchEndAmount: "",
      searchStartDate: "",
      searchEndDate: "",
      dateRange: false,
      amountRange: false,
    };

    if (updatedChips.length > 0) {
      updatedChips.forEach((chip) => {
        const [key, value] = chip.split(":").map((item) => item.trim());
        switch (key) {
          case "Txn ID":
            updatedFormData["searchTxnID"] = value;
            break;
          case "Amount":
            updatedFormData["searchStartAmount"] = value.replace(/\$/g, "");
            break;
          case "Amount From":
            const samount =
              value === "Start"
                ? value
                : value.split(" - To ")[0].trim().replace(/\$/g, "");
            const eamount =
              value === "End"
                ? value
                : value.split(" - To ")[1].trim().replace(/\$/g, "");
            updatedFormData["amountRange"] = true;
            updatedFormData["searchStartAmount"] =
              samount === "Start" ? "" : samount;
            updatedFormData["searchEndAmount"] =
              eamount === "End" ? "" : eamount;
            break;
          case "Date":
            const formattedDate = parseAndFormatDate(value);
            updatedFormData["searchStartDate"] = formattedDate;
            break;
          case "Date From":
            const sdate =
              value === "Start" ? value : value.split(" - To ")[0].trim();
            const edate =
              value === "End" ? value : value.split(" - To ")[1].trim();
            const formattedStartDate =
              sdate === "Start" ? "" : parseAndFormatDate(sdate);
            const formattedEndDate =
              edate === "End" ? "" : parseAndFormatDate(edate);
            updatedFormData["dateRange"] = true;
            updatedFormData["searchStartDate"] = formattedStartDate;
            updatedFormData["searchEndDate"] = formattedEndDate;
            break;
          default:
            break;
        }
      });
    }
    if (
      updatedFormData["searchEndAmount"] === "" &&
      updatedFormData["searchStartAmount"] === ""
    ) {
      localStorage.removeItem("isAmountRangeChecked");
    }
    if (
      updatedFormData["searchEndDate"] === "" &&
      updatedFormData["searchStartDate"] === ""
    ) {
      localStorage.removeItem("isDateRangeChecked");
    }
    props.clearFormDataTransactionHistory();
    props.setFormDataTransactionHistory(updatedFormData);

    if (id) {
      payload = {
        Bussines_id: id,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    } else {
      payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    }

    props.getTransactionHistoryData(payload);

    props.clearFormDataTransactionHistory();

    if (id1) {
      props.history.push(`/${props.userDetails.layout}/fee-refund-history`);
    }
  };

  rows = [];

  const result = props.transactionHistoryData?.data?.map((items) => ({
    Ref_Txn_ID: items?.transactionId,
    Ref_Txn_Date: items?.reftimestamp,
    Ref_Txn_Amount: items?.refamount,
    Type: items?.type,
    Txn_ID: items?.apiTransactionId,
    Amount: items?.amount,
    Date: items?.timestamp,
    Status: items?.status,
    Description: items?.error,
    Id: items?._id,
  }));
  result &&
    result.forEach((v, i) => {
      const Ref_Txn_ID = v.Ref_Txn_ID;
      const Ref_Txn_Date = v.Ref_Txn_Date
        ? moment(v.Ref_Txn_Date).format("MM/DD/YYYY HH:MM:ss")
        : "";
      const Ref_Txn_Amount = v.Ref_Txn_Amount;
      const Type = v.Type;
      const Txn_ID = v.Txn_ID;
      const Amount = v.Amount;
      const Date = v.Date ? moment(v.Date).format("MM/DD/YYYY HH:MM:ss") : "";
      const Status = v.Status;
      const Description = v.Description;
      const Id = v.Id;
      rows.push(
        createData(
          Ref_Txn_ID,
          Ref_Txn_Date,
          Ref_Txn_Amount,
          Type,
          Txn_ID,
          Amount,
          Date,
          Status,
          Description,
          Id
        )
      );
    });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
      };
      props.getTransactionHistoryData(payload);
    }
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };
  const handleBack = (value) => {
    props.history.push(
      `/${props.userDetails.layout}/TransactionReport?ID=null`
    );
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_Id: profileId,
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
        recordPerPage: props?.merchantListData?.transactionHistoryData?.total,
      };
      payload.pageNo = 1;
      props.getRefundHistoryDataExport(payload, (data) => {
        if (data.data) {
          console.log("data", data);
          const mappedLogs = data.data.map((row) => ({
            "Ref Txn ID": row.transactionId,
            "Ref Txn Date": moment(row.reftimestamp).format(
              "MM/DD/YYYY HH:MM:ss"
            ),
            "Ref Txn Amount": row.refamount,
            "Txn ID": row.apiTransactionId,
            type: row.type,
            Amount: row.amount,
            Date: moment(row.timestamp).format("MM/DD/YYYY HH:MM:ss"),
            Status: row.status,
            Description: row.error,
          }));
          exportToCSVProvider(
            mappedLogs,
            "Fee/Refund History",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname
          );
        }
      });
    } else {
      const payload = {
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
        recordPerPage: props?.merchantListData?.transactionHistoryData?.total,
      };
      payload.pageNo = 1;
      props.getRefundHistoryDataExport(payload, (data) => {
        if (data.data) {
          console.log("data", data);
          const mappedLogs = data.data.map((row) => ({
            "Ref Txn ID": row.transactionId,
            "Ref Txn Date": moment(row.reftimestamp).format(
              "MM/DD/YYYY HH:MM:ss"
            ),
            "Ref Txn Amount": row.refamount,
            "Txn ID": row.apiTransactionId,
            type: row.type,
            Amount: row.amount,
            Date: moment(row.timestamp).format("MM/DD/YYYY HH:MM:ss"),
            Status: row.status,
            Description: row.error,
          }));
          exportToCSVProvider(
            mappedLogs,
            "Fee/Refund History",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname
          );
        }
      });
    }
  };

  const exportToPDFList = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_Id: profileId,
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
        recordPerPage: props?.merchantListData?.transactionHistoryData?.total,
      };
      payload.pageNo = 1;
      props.getRefundHistoryDataExport(payload, (data) => {
        const totalCount = data.data.length;
        if (data.data.length > 0) {
          const mappedLogs = data.data.map((row) => [
            row.transactionId,
            moment(row.reftimestamp).format("MM/DD/YYYY HH:MM:ss"),
            row.refamount,
            row.apiTransactionId,
            row.type,
            row.amount,
            moment(row.timestamp).format("MM/DD/YYYY HH:MM:ss"),
            row.status,
            row.error,
          ]);

          const headersName = [
            "Ref Txn ID",
            "Ref Txn Date",
            "Ref Txn Amount",
            "Txn ID",
            "type",
            "Amount",
            "Date",
            "Status",
            "Description",
          ];
          exportToPDF(
            headersName,
            mappedLogs,
            "Fee/RefundHistory",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname,
            totalCount
          );
        }
      });
    } else {
      const payload = {
        sortColumn: orderBy,
        sortOrder: order,
        searchTxnID: props.merchantListData.searchTxnID || "",
        searchStartAmount: props.merchantListData.searchStartAmount || "",
        searchEndAmount: props.merchantListData.searchEndAmount || "",
        searchStartDate: props.merchantListData.searchStartDate || "",
        searchEndDate: props.merchantListData.searchEndDate || "",
        dateRange: props.merchantListData.dateRange || "",
        amountRange: props.merchantListData.amountRange || "",
        recordPerPage: props?.merchantListData?.transactionHistoryData?.total,
      };
      payload.pageNo = 1;
      props.getRefundHistoryDataExport(payload, (data) => {
        const totalCount = data.data.length;
        if (data.data.length > 0) {
          const mappedLogs = data.data.map((row) => [
            row.transactionId,
            moment(row.reftimestamp).format("MM/DD/YYYY HH:MM:ss"),
            row.refamount,
            row.apiTransactionId,
            row.type,
            row.amount,
            moment(row.timestamp).format("MM/DD/YYYY HH:MM:ss"),
            row.status,
            row.error,
          ]);

          const headersName = [
            "Ref Txn ID",
            "Ref Txn Date",
            "Ref Txn Amount",
            "Txn ID",
            "type",
            "Amount",
            "Date",
            "Status",
            "Description",
          ];
          exportToPDF(
            headersName,
            mappedLogs,
            "Fee/Refund History",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname,
            totalCount
          );
        }
      });
    }
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
            <div className={classes.searchWrapper1}>
                <IconsTooltip title="Back" placement="top">
                  <div style={{display:"flex", alignSelf:"flex-end"}}>
                    <ArrowBackIcon color="primary" onClick={() => handleBack("handleBack")}/>
                  </div>
                </IconsTooltip>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SearchRefund
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                {menu?.Role_Permission_Data?.Profile_Code === "Ag" && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                )}
                {menu?.Role_Permission_Data?.Profile_Code !== "Ag" &&
                  props?.managerTypeData?.MgType !== "not main" && (
                    <IconsTooltip title="Export" placement="top">
                      <div className={classes.appSvgIcons}>
                        <ExportIcon
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleToggle}
                          ref={anchorRef}
                        />
                      </div>
                    </IconsTooltip>
                  )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={exportListData}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to Excel
                            </MenuItem>
                            <MenuItem
                              onClick={exportToPDFList}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to PDF
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <SearchFields
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => (
                  <SearchRefund
                    close={() => handleSearchClose()}
                    seacrhChipData={(data) => displayAndDeleteChips(data)}
                    clearChips={() => setChipValue([])}
                  />
                )}
              />
              {menu?.Role_Permission_Data?.Profile_Code === "Ag" && (
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
              )}
              {menu?.Role_Permission_Data?.Profile_Code !== "Ag" &&
                props?.managerTypeData?.MgType !== "not main" && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                )}
              <Popper
                open={openImportFileModal}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: "10",
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseModel}>
                        <MenuList
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Export to Excel
                          </MenuItem>
                          <MenuItem
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Export to PDF
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        )}
          <SearchChips
            searchValues={chipValue}
            handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
          />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell className={classes.cellText}>
                    {row.Ref_Txn_ID}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Ref_Txn_Date}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Ref_Txn_Amount}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Txn_ID}
                  </TableCell>

                  <TableCell className={classes.cellText} align="left">
                    {row.Type}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {parseFloat(row.Amount).toFixed(2)}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Date}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Status}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    style={{ whiteSpace: "normal" }}
                  >
                    {row.Description}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props?.transactionHistoryData?.total,
                    },
                  ]}
                  colSpan={12}
                  count={props?.transactionHistoryData?.total}
                  rowsPerPage={rowsPerPage || 5}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

RefundHistory.propTypes = {
  history: PropTypes.any,
  userDetails: PropTypes.object,
  getTransactionHistoryData: PropTypes.func,
  getRefundHistoryDataExport: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  transactionHistoryData: app.genrateOfferPatient.transactionHistoryData,
  transactionListData: app.genrateOfferPatient,
  managerTypeData: app.genrateOfferPatient.emailData1,
});
export default connect(mapStateToProps, {
  emptyReduxState,
  setFormDataTransactionHistory,
  clearFormDataTransactionHistory,
  getTransactionHistoryData,
  getRefundHistoryDataExport,
})(RefundHistory);
