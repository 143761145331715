import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { exportToPDF } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import ErrorSearchDialog from "../../components/DialogBox/ErrorSearchDialog";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import {
  sendInviteApiCall,
  sendInviteManagerApiCall,
} from "../../actions/Operations";
import {
  getErrorCodeData,
  emptyReduxState,
  setFormData,
  clearFormData,
  getErrorCodeExport,
} from "../../Redux/Actions/genrateOfferPatient";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);
function createData(
  ErrorCode,
  type,
  Description,
  Entered_On
) {
  return {
    ErrorCode,
    type,
    Description,
    Entered_On,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "ErrorCode",
      numeric: false,
      disablePadding: true,
      label: "Error Code",
      headClassName: classes.DBA_Name,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
      headClassName: classes.Assign_To,
    },
    {
      id: "Description",
      numeric: true,
      disablePadding: false,
      label: "Description",
      headClassName: classes.email,
    },
    {
      id: "Entered_On",
      numeric: true,
      disablePadding: false,
      label: "Updated On",
      headClassName: classes.Phone,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ErrorCodes = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(
    props.errorCodeData?.errorCodeData?.page
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Entered_On");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [id, setId] = React.useState(null);
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    setPage(1);
    if (id) {
      const payload = {
        pageNo: 1,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      };

      props.getErrorCodeData(payload);
    }
  }, [id]);

  React.useEffect(() => {
    setPage(1);
    const payload = {
      pageNo: 1,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
    };
    props.getErrorCodeData(payload);
  }, []);

  React.useEffect(() => {
    if (props?.errorCodeData?.errorCodeData?.page !== page) {
      setPage(props?.errorCodeData?.errorCodeData?.page);
    }
    if (props?.errorCodeData?.errorCodeData?.limit !== rowsPerPage) {
      setRowsPerPage(props?.errorCodeData?.errorCodeData?.limit);
    }
  }, [props]);

  React.useEffect(() => {
    return () => {
      let updatedFormData = {
        searchErrorCode: "",
        searchDescription: "",
        searchDate: "",
        searchType: null,
      };
      props.setFormData(updatedFormData);
      props.clearFormData();
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const requestData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchErrorCode: props?.errorCodeData?.searchErrorCode || "",
      searchDescription: props?.errorCodeData?.searchDescription || "",
      searchDate: props?.errorCodeData?.searchDate || "",
      searchType: props?.errorCodeData?.searchType || "",
      pageNo: newPage + 1,
    };
    props.getErrorCodeData(requestData);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: parseInt(event.target.value, 10),
      sortColumn: orderBy,
      sortOrder: order,
      searchErrorCode: props?.errorCodeData?.searchErrorCode || "",
      searchDescription: props?.errorCodeData?.searchDescription || "",
      searchDate: props?.errorCodeData?.searchDate || "",
      searchType: props?.errorCodeData?.searchType || "",
      pageNo: 1,
    };
    props.getErrorCodeData(requestData);
  };

  const displayAndDeleteChips = (searchData) => {
    const chips = [];
    if (searchData.searchErrorCode) {
      chips.push(`Error Code: ${searchData.searchErrorCode}`);
    }
    if (searchData.searchDescription) {
      chips.push(`Description: ${searchData.searchDescription}`);
    }
    if (searchData.searchDate) {
      chips.push(`Date: ${searchData.searchDate}`);
    }
    if (searchData.searchType) {
      chips.push(`Type: ${searchData.searchType}`);
    }
    setChipValue(chips);
  };

  const handleDeleteChip = (chipToDelete) => {
    const updatedChips = chipValue.filter((chip) => chip !== chipToDelete);
    setChipValue(updatedChips);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    let payload;
    let updatedFormData = {
      searchErrorCode: "",
      searchDescription: "",
      searchType: "",
      searchDate: "",
    };

    if (updatedChips.length > 0) {
      updatedChips.forEach((chip) => {
        const [key, value] = chip.split(":").map((item) => item.trim());
        switch (key) {
        case "Error Code":
          updatedFormData["searchErrorCode"] = value;
          break;
        case "Description":
          updatedFormData["searchDescription"] = value;
          break;
        case "Date":
          updatedFormData["searchDate"] = value;
          break;
        case "Type":
          updatedFormData["searchType"] = value;
          break;
        default:
          break;
        }
      });
    }
    props.clearFormData();
    props.setFormData(updatedFormData);

    if (profileCode === "Mg") {
      payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    } else {
      payload = {
        Profile_Code: profileCode,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    }

    props.getErrorCodeData(payload);

    props.clearFormData();
  };

  rows = [];
  const result = props.errorCodeData.errorCodeData?.data?.map((items) => ({
    ErrorCode: items?.ErrorCode,
    type: items?.type,
    Description: items?.Description,
    Entered_On: items?.Entered_On,
  }));

  result &&
        result.forEach((v, i) => {
          const ErrorCode = v?.ErrorCode;
          const type = v?.type;
          const Description = v?.Description;
          const Entered_On = moment
            .utc(v?.Entered_On.$date)
            .format("MM/DD/YY HH:mm");
          rows.push(
            createData(
              ErrorCode,
              type,
              Description,
              Entered_On
            )
          );
        });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    const requestData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: rowsPerPage,
      sortColumn: property,
      sortOrder: newOrder,
      searchErrorCode: props?.errorCodeData?.searchErrorCode || "",
      searchDescription: props?.errorCodeData?.searchDescription || "",
      searchDate: props?.errorCodeData?.searchDate || "",
      searchType: props?.errorCodeData?.searchType || "",
      pageNo: page,
    };
    props.getErrorCodeData(requestData);
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const exportedBy =
    props.mainData?.users?.userDetailsById &&
    props.mainData?.users?.userDetailsById?.Users_Data[0]?.Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: 9999999,
      sortColumn: orderBy,
      sortOrder: order,
      searchErrorCode: props?.errorCodeData?.searchErrorCode || "",
      searchDescription: props?.errorCodeData?.searchDescription || "",
      searchDate: props?.errorCodeData?.searchDate || "",
      searchType: props?.errorCodeData?.searchType || "",
      pageNo: 1,
    };
    filterData.recordPerPage = 99999999;
    filterData.pageNo = 1;
    props.getErrorCodeExport(filterData, (data) => {
      if (data) {
        const mappedLogs = data.data.map((row) => ({
          "Error Code": row.ErrorCode,
          Type: row.type,
          Description: row.Description,
          "Update On": moment
            .utc(row.Entered_On?.$date)
            .format("DD/MM/YY HH:mm"),
        }));
        exportToCSVProvider(
          mappedLogs,
          "Error Code",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname
        );
      }
    });
  };

  const exportToPDFList = () => {
    const filterData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: 9999999,
      sortColumn: orderBy,
      sortOrder: order,
      searchErrorCode: props?.errorCodeData?.searchErrorCode || "",
      searchDescription: props?.errorCodeData?.searchDescription || "",
      searchDate: props?.errorCodeData?.searchDate || "",
      searchType: props?.errorCodeData?.searchType || "",
      pageNo: 1,
    };
    props.getErrorCodeExport(filterData, (data) => {
      const totalCount = data.data.length;
      if (data) {
        const mappedLogs = data.data.map((row) => [
          row.ErrorCode,
          row.type,
          row.Description,
          moment.utc(row.Entered_On?.$date).format("DD/MM/YY HH:mm"),
        ]);

        const headersName = [
          "Error Code",
          "Type",
          "Description",
          "Update On",
        ];
        exportToPDF(
          headersName,
          mappedLogs,
          "Error Code",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname,
          totalCount
        );
      }
    });
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <ErrorSearchDialog
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <ErrorSearchDialog
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        <SearchChips
          searchValues={chipValue}
          handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell
                    className={classes.cellText}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#794411",
                        }}
                      >
                        {row.ErrorCode}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.type}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    style={{ whiteSpace: "normal", width: "60vw" }}
                  >
                    {row.Description}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {row.Entered_On}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props?.errorCodeData?.errorCodeData?.total,
                    },
                  ]}
                  colSpan={12}
                  count={props.errorCodeData.errorCodeData.total}
                  rowsPerPage={rowsPerPage || 5}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ErrorCodes.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getMerchantListData: PropTypes.func,
  getTransactionListData: PropTypes.func,
  getErrorCodeData: PropTypes.func,
  sendInviteApiCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  sendInviteManagerApiCall: PropTypes.func,
  getErrorCodeExport: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  transactionListData: app.genrateOfferPatient,
  errorCodeData: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  clearFormData,
  setFormData,
  getErrorCodeExport,
  getErrorCodeData,
  sendInviteApiCall,
  emptyReduxState,
  sendInviteManagerApiCall,
})(ErrorCodes);
