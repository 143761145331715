import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CoustomButton from "components/CustomButtons/Button";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import ChipInput from "material-ui-chip-input";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import noDataImage from "../../assets/img/noDataImage.png";
import {
  getProviderStatus,
  changeSingleStatus,
  getProviderByFilterData,
  addSearchData,
  getProviderDetailsById,
} from "../../Redux/Actions/ProvideRegistration";
import { exportProviderCall } from "../../actions/ProviderPanel.action";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import {
  sendInviteApiCall,
  sendInviteManagerApiCall,
} from "../../actions/Operations";
import {
  getMerchantListData,
  getTransactionListData,
  emptyReduxState,
} from "../../Redux/Actions/genrateOfferPatient";

import InviteManagerModal from "../../components/Modal/InvitemanagerModal";

const IconsTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);
function createData(
  senderTitle,
  managerName,
  recipientTitle,
  transactionId,
  refTransactionId,
  transType,
  amount,
  timestamp,
  status
) {
  return {
    senderTitle,
    managerName,
    recipientTitle,
    transactionId,
    refTransactionId,
    transType,
    amount,
    timestamp,
    status,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "senderTitle",
      numeric: false,
      disablePadding: true,
      label: "Sender",
      headClassName: classes.DBA_Name,
    },
    {
      id: "recipientTitle",
      numeric: false,
      disablePadding: false,
      label: "Receiver",
      headClassName: classes.Assign_To,
    },
    {
      id: "transactionId",
      numeric: true,
      disablePadding: false,
      label: "Txn ID",
      headClassName: classes.email,
    },
    {
      id: "refTransactionId",
      numeric: true,
      disablePadding: false,
      label: "Reference Txn ID",
      headClassName: classes.Phone,
    },
    {
      id: "transType",
      numeric: true,
      disablePadding: false,
      label: "Txn Type",
      headClassName: classes.Phone,
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      headClassName: classes.Phone,
    },
    {
      id: "timestamp",
      numeric: true,
      disablePadding: false,
      label: "Date",
      headClassName: classes.Phone,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const OldTransactionList = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(
    props.transactionListData?.transactionListData?.page
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("timestamp");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);

  const [temp, setTemp] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [id, setId] = React.useState(null);
  const profileId = localStorage.getItem("profileId");
  const [inviteManagerModalOpen, setInviteManagerModalOpen] = React.useState(false);

  const handleToggle = () => {
    setImportFileModalOpen(prevOpen => !prevOpen);
  };

  const handleCloseModel = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    if (id) {
      const payload = {
        Bussines_id: id,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
      };

      props.getTransactionListData(payload);
    }
  }, [id]);

  React.useEffect(() => {
    if (id == null) {
      const payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
      };
      props.getTransactionListData(payload);
    }
  }, []);

  React.useEffect(() => {
    if (props?.transactionListData?.transactionListData?.page !== page) {
      setPage(props?.transactionListData?.transactionListData?.page);
    }
    if (
      props?.transactionListData?.transactionListData?.limit !== rowsPerPage
    ) {
      setRowsPerPage(props?.transactionListData?.transactionListData?.limit);
    }
  }, [props]);

  const handleClickOpen = () => {
  };

  const handleClose = value => {
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (id) {
      const requestData = {
        Bussines_id: id,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: newPage + 1,
      };
      props.getTransactionListData(requestData);
    } else if (id == null) {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: newPage + 1,
      };
      props.getTransactionListData(requestData);
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (id) {
      const requestData = {
        Bussines_id: id,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: page,
      };
      props.getTransactionListData({ ...requestData });
    } else if (id == null) {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: page,
      };
      props.getTransactionListData({ ...requestData });
    }
  };

  const handleDeleteChip = chip => {
    if (chipValue.length) {
      setChipValue(chipValue.filter(v => v !== chip));
      const requestData = { ...props.tableData.searchDetails };
      const fillterPreChip = chipValue.filter(v => v !== chip);
      const filterRemoveChip = chipValue.filter(v => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Status") {
            const filterStatusCode = props.tableData.statusList.Provider_Status_Data.filter(
              item => item.Provider_Status.trim() === splitSearchName[1].trim()
            );
            requestData.searchStatus = filterStatusCode.length > 0
              ? filterStatusCode[0].Status_Code
              : "";
          }
          if (splitSearchName[0] === "Agent") {
            const filterAgentName = props.tableData.agentList.data.filter(
              key => key.Name.trim() === splitSearchName[1].trim()
            );
            requestData.searchAgentId = filterAgentName[0].User_Id;
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = "";
          }
          if (splitSearchName[0] === "Status") {
            requestData.searchStatus = "";
          }
          if (splitSearchName[0] === "Agent") {
            requestData.searchAgentId = "";
          }
        });
      }
      requestData.recordPerPage = 10;
      requestData.pageNo = 1;
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
    } else {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchAgentId = "";
      requestData.searchStatus = "";
      requestData.recordPerPage = 10;
      requestData.pageNo = 1;
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue(chipValue);
    }
    if (chipValue.length === 0) {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchAgentId = "";
      requestData.searchStatus = "";
      requestData.recordPerPage = 10;
      requestData.pageNo = 1;
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue([]);
    }
    setTemp(temp);
  };

  rows = [];

  const result = props.transactionListData.transactionListData?.data?.map(
    items => ({
      senderTitle: items?.senderTitle,
      managerName: items?.managerName,
      transactionId: items?.transactionId,
      recipientTitle: items?.recipientTitle,
      refTransactionId: items?.refTransactionId,
      receiverFirstName: items?.receiverFirstName,
      receiverLastName: items?.receiverLastName,
      Phone_Number: items?.Phone_Number,
      transType: items.transType,
      amount: items.amount,
      timestamp: items.timestamp,
      status: items.status,
    })
  );

  result
        && result.forEach((v, i) => {
          const senderTitle = v.senderTitle;
          const managerName = v.managerName;
          const recipientTitle = v.recipientTitle;
          const transactionId = v.transactionId;
          const refTransactionId = v.refTransactionId;
          const transType = v.transType;
          const amount = v.amount;
          const timestamp = moment
            .utc(v.timestamp)
            .format("MM/DD/YYYY hh:mm A");
          const status = v.status;
          rows.push(
            createData(
              senderTitle,
              managerName,
              recipientTitle,
              transactionId,
              refTransactionId,
              transType,
              amount,
              timestamp,
              status
            )
          );
        });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    if (id) {
      const requestData = {
        Bussines_id: id,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        pageNo: page,
      };
      props.getTransactionListData(requestData);
    } else if (id == null) {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        pageNo: page,
      };
      props.getTransactionListData(requestData);
    }
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.merchant,
                  permissionCheck.option.export
                )
                  && (actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.exportToExcel
                  )
                    || actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.merchant,
                      permissionCheck.option.exportToPdf
                    )) && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            // autoFocusItem={openImportFileModal}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                // onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                // onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {inviteManagerModalOpen && (
                  <InviteManagerModal
                    open={inviteManagerModalOpen}
                    onClose={() => setInviteManagerModalOpen(false)}
                    props={props}
                    rows={rows}
                    sendInviteManagerApiCall={props.sendInviteManagerApiCall}
                    Bussines_id={id}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <ChipInput
                    defaultValue={chipValue.length > 0 ? chipValue : []}
                    classes={{ chipContainer: classes.chipContainer }}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              {actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.export
              )
                && (actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.merchant,
                  permissionCheck.option.exportToExcel
                )
                  || actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.exportToPdf
                  )) && (
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
              )}
              <Popper
                open={openImportFileModal}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: "10",
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseModel}>
                        <MenuList
                          // autoFocusItem={openImportFileModal}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {actionPermission(
                            permissionCheck.Category.setup,
                            permissionCheck.Page.merchant,
                            permissionCheck.option.exportToExcel
                          ) && (
                            <MenuItem
                              // onClick={exportListData}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to Excel
                            </MenuItem>
                          )}
                          {actionPermission(
                            permissionCheck.Category.setup,
                            permissionCheck.Page.merchant,
                            permissionCheck.option.exportToPdf
                          ) && (
                            <MenuItem
                              // onClick={exportToPDFList}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to PDF
                            </MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        )}
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign}>
                  <TableCell
                    className={classes.cellText}
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    <div>
                      <div>{row.senderTitle}</div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#794411",
                        }}
                      >
                        {row.managerName}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.recipientTitle}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.transactionId}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.refTransactionId}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.transType}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.amount}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.timestamp}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    // onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props.transactionListData.transactionListData.total
                    },
                  ]}
                  colSpan={12}
                  count={props.transactionListData.transactionListData?.total}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

OldTransactionList.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  getProviderStatus: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  addSearchData: PropTypes.func,
  mainData: PropTypes.object,
  exportProviderCall: PropTypes.func,
  getMerchantListData: PropTypes.func,
  getTransactionListData: PropTypes.func,
  sendInviteApiCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  sendInviteManagerApiCall: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  transactionListData: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  getProviderStatus,
  changeSingleStatus,
  getProviderByFilterData,
  getProviderDetailsById,
  addSearchData,
  exportProviderCall,
  getMerchantListData,
  getTransactionListData,
  sendInviteApiCall,
  emptyReduxState,
  sendInviteManagerApiCall,
})(OldTransactionList);
